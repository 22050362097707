export const tableConfig = {
  baseStyle: {
    fontFamily: "myBody",
    td: {
      fontSize: "16px",
      fontFamily: "myBody",
    },
    th: {
      fontSize: "16px",
      fontFamily: "myBody",
    },
  },
};
