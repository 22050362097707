import { defineStyleConfig } from "@chakra-ui/react";


export const breadcrumbConfig =  defineStyleConfig(
  {
    baseStyle: {
      color: "mainColor.100",
      fontSize: "xs",
      transition: "all .5s",
      fontWeight: "bold",
      fontFamily: "myBody",
  
      container: {
        fontSize: "xs",
        transition: "all .5s",
        fontWeight: "bold",
        fontFamily: "myBody",
      },
      link: {
        transition: "all .5s",
        color: "mainText.100",
        _hover: {
          textDecoration: "none",
          color: "purpulС.200",
          transition: "all .5s",
        },
      },
      separator: {
        color: "mainText.100",
      },
    },
  
    variants: {
      isCurrentPage: {
        color: "mainText.200",
        _hover: {
          textDecoration: "none",
          color: "mainText.200",
        },
      },
    },
  },
)