export const buttonConfig = {
  baseStyle: {
    fontFamily: "myBody",
  },

  sizes: {
    smC: {
      fontSize: "12px",
      width: "70px",
      height: "28px",
    },
    mdC: {
      fontSize: "16px",
      width: "100px",
      height: "48px",
    },

    xlC: {
      fontSize: "18px",
      width: "133px",
      height: "48px",
    },
    xlC2: {
      fontSize: "18px",
      width: "150px",
      height: "48px",
    },
    xlCbase: {
      fontSize: "20px",
      width: "80px",
      height: "48px",
    },
    xxlC: {
      fontSize: "24px",
      fontWeight: "700",
      width: "200px",
      height: "70px",
    },
    dropdowndarkXL: {
      fontSize: "16px",
      width: "202px",
      height: "38px",
    },

    crossOnBoxXl: {
      fontSize: "16px",
      width: "202px",
      height: "38px",
    },
  },

  variants: {
    solid: (props) => ({
      background: "yellowC.200",
      color: "mainText.200",
      transition: "all .3s",
      _hover: {
        background: "yellowC.300",
        transition: "all .3s",
      },
      _active: {
        background: "yellowC.200",
        transition: "all .3s",
      },
    }),
    solidPurple: (props) => ({
      background: "purpleC.200",
      color: "whiteAlfa.100",
      transition: "all .3s",
      _hover: {
        background: "purpleC.300",
        transition: "all .3s",
      },
      _active: {
        background: "purpleC.200",
        transition: "all .3s",
      },
    }),
    solidBlack: (props) => ({
      background: "blackAlpha.700",
      color: "yellowC.200",
      transition: "all .3s",
      _hover: {
        background: "blackAlpha.900",
        transition: "all .3s",
      },
      _active: {
        background: "blackAlpha.700",
        transition: "all .3s",
      },
    }),
    solidBlackBase: (props) => ({
      background: "blackAlpha.700",
      color: "yellowC.200",
      fontSize: "20px",
      width: "100px",
      height: "48px",
      transition: "all .3s",
      _hover: {
        background: "blackAlpha.900",
        transition: "all .3s",
      },
      _active: {
        background: "blackAlpha.700",
        transition: "all .3s",
      },
    }),
    underline: (props) => ({
      background: "transparent",
      color: "mainText.200",
      textDecoration: "underline",
      transition: "all .5s",
      _hover: {
        background: "transparent",
        transition: "all .5s",
        textDecoration: "none",
      },
      _active: {
        textDecoration: "underline",
        transition: "all .3s",
      },
    }),
    outline: (props) => ({
      background: "transparent",
      borderRadius: "5px",
      border: "2px solid",
      borderColor: "mainText.200",
      color: "mainText.200",
      _hover: {
        background: "transparent",
        transition: "all .3s",
        textDecoration: "underline",
        border: "none",
      },
      _active: {
        background: "underline",
        border: "none",
      },
    }),
    dropdown: (props) => ({
      background: "transparent",
      textDecoration: "underline",
      color: "mainText.200",
      _hover: {
        background: "transparent",
        transition: "all .3s",
        textDecoration: "underline",
      },
      _active: {
        background: "underline",
      },
    }),
    dropdownDark: (props) => ({
      background: "transparent",
      textDecoration: "underline",
      color: "mainText.200",
      _hover: {
        background: "transparent",
        transition: "all .3s",
        textDecoration: "underline",
      },
      _active: {
        background: "underline",
      },
    }),
    dropdowndark: (props) => ({
      background: "mainText.200",
      color: "white",
    }),
    crossOnBox: (props) => ({
      background: "transparent",
      // color: "white",
      border: "3px solid",
      borderColor: "grayC.100",
      padding: 0,

      width: "18px",
      height: "18px",
    }),
  },
};
