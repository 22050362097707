import "@fontsource-variable/red-hat-display";
import "@fontsource/sansita-swashed";
import "@fontsource/sansita-swashed/400.css";
import { extendTheme, theme as base } from "@chakra-ui/react";
import { breadcrumbConfig as Breadcrumb } from "./breadCrumbConfig";
import { linkConfig as Link } from "./linkConfig";
import { buttonConfig as Button } from "./buttonConfig";
import { CheckboxCustom as Checkbox } from "./checkBoxConfig";
import { inputCustom as Input } from "./InputConfig";
import { textareaConfig as Textarea } from "./textarea";
import { selectConfig as Select } from "./selectConfig";
import { tableConfig as Table } from "./tableConfig";

export const theme = extendTheme({
  colors: {
    whiteAlfa: {
      100: "#ffffff",
    },

    darkBackground: {
      100: "#161718",
      200: "#191919",
    },

    mainText: {
      100: "#7E7E7E",
      200: "#363636",
    },

    yellowC: {
      100: "#FFF9E1",
      200: "#DBFE01",
      300: "#C5E500",
      400: "#F5D048",
      500: "#F0CB42",
    },

    purpleC: {
      100: "#B392FF",
      200: "#7037F5",
      300: "#5A2DDC",
    },

    grayC: {
      100: "#CDCDCD",
      200: "#7A7A7A",
      300: "#FAFAFA",
      400: "#EFEFEF",
      500: "#CED9E9",
      600: "#EBEBEB",
      700: "#FCFCFC",
      800: "#E4E4E4",
      900: "#F2F5FB",
    },

    redC: {
      100: "#EB5757",
    },

    linkBlueC: {
      100: "#0074B9",
      200: "#0065A2",
      300: "#00578A",
    },
    pinkC: {
      100: "#ED77A8",
    },
    greenC: {
      100: "#6DEB74",
    },
  },

  fontSizes: {
    "5xl": "50px",
  },

  fonts: {
    myHeading: `'Red Hat Display Variable', ${base.fonts?.heading}, sans-serif`,
    myBody: `'Red Hat Display Variable', ${base.fonts?.body}, sans-serif`,
    body: `'Red Hat Display Variable', ${base.fonts?.body}, sans-serif`,
  },

  styles: {
    global: {
      "::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      // "::-webkit-scrollbar-track": {
      //   background: "#f1f1f1", // Track background color
      // },
      "::-webkit-scrollbar-thumb": {
        background: "#b8b8b8", // Scrollbar color
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#7037F5", // Color on hover
        // background: "#555", // Color on hover
      },
    },
  },
  components: {
    Link,
    Breadcrumb,
    Button,
    Checkbox,
    Input,
    Textarea,
    Select,
    Table,
  },
});
