// gatsby-browser.js and gatsby-ssr.js
import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./chakraUiConfig/theme.config";
import { PageViews } from "@piwikpro/gatsby-plugin-piwik-pro";

export const wrapRootElement = ({ element }) => (
  <ChakraProvider theme={theme}>{element}</ChakraProvider>
);

const onRouteUpdate = () => {
  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() =>
        setTimeout(() => PageViews.trackPageView(), 0)
      );
    });
  } else {
    setTimeout(() => PageViews.trackPageView(), 32);
  }
};

export { onRouteUpdate };
