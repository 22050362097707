export const selectConfig = {
  baseStyle: {
    fontFamily: "myBody",
  },

  variants: {
    customSelect: (props) => ({
      field: {
        bg: "darkBackground.100",
        color: "whiteAlfa.100",
        borderRadius: "30px",
        borderColor: "mainText.200",
        border: "1px solid",
      },
      icon: {},
    }),
  },
};
