import { defineStyleConfig } from "@chakra-ui/react";

export const linkConfig = defineStyleConfig({
  baseStyle: {
    fontFamily: "myBody",
    padding: "5px 10px",
  },

  sizes: {
    smL: {
      fontSize: "12px",
      fontWeight: "300",
    },
    mL: {
      fontSize: "14px",
      fontWeight: "400",
    },
    xlL: {
      fontSize: "16px",
      fontWeight: "500",
    },
  },

  variants: {
    blackLink: (props) => ({
      color: props.colorMode === "dark" ? "white" : "mainText.200",
      fontWeight: "300",

      _hover: {
        textDecoration: "none",
      },
    }),
    blackLinkBase: (props) => ({
      color: props.colorMode === "dark" ? "white" : "mainText.200",
      fontWeight: "300",
      fontSize: "20px",
      _hover: {
        textDecoration: "none",
      },
    }),

    footerLink: (props) => ({
      color: props.colorMode === "dark" ? "white" : "mainText.200",
      fontWeight: "400",
      fontSize: "16px",
    }),
    underlineLink: (props) => ({
      color: "mainText.200",
      textDecoration: "underline",
      transition: "all .5s",
      _hover: {
        color: "linkBlueC.100",
        transition: "all .5s",
      },
      _active: {
        color: "linkBlueC.200",
        transition: "all .5s",
      },
    }),
    lawsLink: () => ({
      color: "linkBlueC.100",
      textDecoration: "underline",
      transition: "all .5s",
      _hover: {
        textDecoration: "none",
        color: "linkBlueC.100",
        transition: "all .5s",
      },
      _active: {
        textDecoration: "none",
        color: "linkBlueC.300",
        transition: "all .5s",
      },
    }),
  },
});
