export const textareaConfig = {
  baseStyle: {
    fontFamily: "myBody",
    fontSize: "16px",
    height: "126px",
    border: "2px solid",
    borderColor: "transparent",
    background: "mainText.200",
    color: "whiteAlfa.100",
    "::placeholder": {
      color: "grayC.500", // Set your desired color here
    },
  },
};
