export const inputCustom = {
  variants: {
    basic: () => ({
      field: {
        fontFamily: "myBody",
        fontSize: "16px",
        height: "50px",
        border: "2px solid",
        borderColor: "transparent",
        bg: "mainText.200",
        color: "whiteAlfa.100",
        "::placeholder": {
          color: "grayC.500", // Set your desired color here
        },
        _focus: {
          borderColor: "purpleC.200",
        },
      },
    }),
    small: () => ({
      field: {
        fontFamily: "myBody",
        fontSize: "14px",
        height: "40px",
        border: "2px solid",
        borderColor: "transparent",
        bg: "mainText.200",
        color: "whiteAlfa.100",
        "::placeholder": {
          color: "grayC.500", // Set your desired color here
        },
        _focus: {
          borderColor: "purpleC.200",
        },
      },
    }),
  },
};
