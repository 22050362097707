export const CheckboxCustom = {
  variants: {
    standart: () => ({
      container: {
        padding: "5px 15px",
        bg: "transparent",
        transition: "all .5s",
        _hover: {
          bg: "grayC.300",
          transition: "all .5s",
        },
      },
      label: {
        color: "mainText.200",
      },
      control: {
        bg: "transparent",
        _checked: {
          bg: "darkBackground.100",
          borderColor: "darkBackground.100",
          _hover: {
            bg: "darkBackground.100",
            borderColor: "darkBackground.100",
          },
        },
        _disabled: {
          bg: "transparent",
        },
        _focus: {
          boxShadow: `none`,
          _checked: {
            boxShadow: `none`,
          },
        },
        _hover: {
          bg: "transparent",
        },
      },
    }),

    disabled: () => ({
      control: {
        _disabled: {
          bg: "transparent",
        },
        _checked: {
          _disabled: {
            bg: "transparent",
          },
        },
      },
      icon: {
        color: "grayC.100",
      },
    }),
  },
};
